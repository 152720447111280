// 
// 
// background-images.scss
//
//


// Markup:
//
// <section> > <img.bg-image>

section{
  position: relative;
}
.card-blog {

  &:after {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    background: radial-gradient(ellipse at bottom left, rgba($black,.9), transparent);
    top: 0;
    left: 0;
  }
}
.bg-image{
  &:not([class*='absolute']){
    position: absolute;
  }
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  mix-blend-mode: multiply;
  + .card-body{
    position: relative;
    z-index: 1;
  }
}

img.bg-image{
  object-fit: cover;
}