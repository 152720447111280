form {
    fieldset {
        @extend .form-row;

        .clearfix {
            @extend .col-12;
        }

        input,
        textarea {
            @extend .form-control;
        }

        label {
            margin: 1em 0 0;
            font-size: 0.85em;
        }
    }

    .error {

        input,
        textarea {
            border-color: $red;
        }

        .help-inline {
            color: $red;
            font-size: 0.85em;
        }
    }

    .form-navigation {
        ul {
            list-style-type: none;
            padding: 1.5em 0.5em 0;
        }
    }
}

// .mauticform-innerform {
//     @extend .container;
// }


// .mauticform-page-wrapper {
//     @extend .row;
// }

.mauticform_wrapper {
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
}

.mauticform-page-wrapper {
    display: flex !important;
    @extend .row;
    textarea {
        min-height: 120px;
    }
}

.mauticform-text,
.mauticform-email,
.mauticform-tel,
.mauticform-select,
.mauticform-button-wrapper {
    @extend .form-group;

    input,
    select,
    textarea {
        @extend .form-control;
        border-radius: 0px;
        background: lighten(theme-color('primary-3'),6%);
        border: none;
        color: darken(theme-color('primary-3'),25%);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: .875rem;
        &::placeholder {
            color: darken(theme-color('primary-3'),25%);
        }
    }
    
    .btn-default {
        // @extend .btn-primary;
        // font-size: .875rem;
        // color: darken(theme-color('primary-3'),25%);
        &:hover{
            background-color: transparent;
        }
    }
}

.mauticform-select {
    select {
        @extend .custom-select;
    }
}

.mauticform-errormsg {
    color: theme-color('primary');
    font-size: 0.75em;
}

.mauticform-has-error {
    input {
        border-style: dashed !important;
        border-width: 2px;
        border-color: theme-color('primary');
    }
}

.footer-form {
    @extend .pt-0;
    @extend .mt-5;
}
