.card {
    border: none;
    border-radius: inherit;
    margin-bottom: 4.5rem;
    background-color: transparent;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.15);

    &.card-blog {
        color: $white;
    }

    .card-cate {
        min-height: 200px;
    }

    .card-text {

    }

    &:hover {
        background-color: transparent;
        box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.15);
    }

    .card-body {
        padding: 2rem 2rem 0;
    }

    .card-img-top {
        border-radius: inherit;
    }

    &.card-icon-3 {
        min-height: 460px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.75) 100%);

        &:after {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            z-index: -1;
            position: absolute;
            mix-blend-mode: screen;
        }

        .card-img-top {
            mix-blend-mode: multiply;
        }

        .card-content {
            color: $white;
            bottom: 1em;
            z-index: 1;
            position: absolute;

            a,
            p {
                font-weight: 400;
            }

            .link-item {
                text-decoration: underline;
            }

        }
    }

    .badge {
        z-index: 10;
        border-radius: 0;
    }
}

.team-result {

    div[class*="col-"] {
        @extend .mb-5;
    }

    .card-wrapper {
    }
    
    .person-card {
        @extend .card-icon-3;
        @extend .card-body;
    }
}


.person-card {
    @extend .card-icon-3;
    @extend .card-body;
}

.plannerStoerer {
    height: 550px; 
    max-width: 1600px;
    margin: 0 auto;

    @include media-breakpoint-down (sm) {
        height: 350px; 
    }

    display: block; 
    background-repeat: no-repeat; 
    background-position: center center; 
    background-size: cover;
}