h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4,
.display-1,
.display-2,
.display-3,
.display-4 {
    letter-spacing: 0;
    line-height: 1.25;
    .text-white & {
        @extend .text-white;
    }
}

.no-hyphens {
    hyphens: none;
}

.lead {
    font-size: $lead-font-size;
    font-family: $headings-font-family;
    &.strong,
    &strong {
        font-weight: $font-weight-bold;
    }
    h3 {
        @extend .h3;
        @extend strong;
    }
}

h1, .h1 {
    font-size: $font-size-base*3.3333333333 !important;
}

.section-header {
    h2, .h2 {
        margin-bottom: $grid-gutter-width*2;  
    }
}

@include media-breakpoint-down(lg) {
    // html {
    //     font-size: .8889rem;
    // }
}

@include media-breakpoint-down(sm) {
    html {
        // font-size: .7778rem;
    }

    h1,
    .h1 {
        font-size: $font-size-base*2 !important;
    }
    h2,
    .h2 {
        font-size: $font-size-base*1.3333333333 !important;
    }
    .lead {
        font-size: $font-size-base*1.1666666667 !important;
    }
}

@include media-breakpoint-down(md) {
    .display-2 {
        font-size: $display4-size;
    }
}

.display-1,
.display-2,
.display-3,
.display-4 {
    margin-bottom: 1.5rem;
}

main {
    ul {
        list-style-type: none;
        padding-left: 1em;

        li:not(.nav-item) {
            &:before {
                content: '–';
                position: absolute;
                margin-left: -1em;
                color: theme-color('primary');
            }
        }
    }

    p {
        a {
            text-decoration: underline;
        }
    }
}


ul {
    &.list-unstyled {
        list-style-type: initial;
        padding-left: initial;

        li {
            &:before {
                content: initial;
                margin-left: initial;
                color: initial;
                position: initial;
                display: none;
            }
        }
    }
}


.hover-links {
    font-family: $headings-font-family;
    font-size: 1.3333333em;

    .link-block {
        position: relative;
        border-top: 2px solid theme-color('primary-3');
        @extend .mb-0;
        padding: .5em 1.25em .5em 0;

        a {
            display: block;
        }

        .text-right {
            position: absolute;
            right: 0;
            top: .75em;
            transform: rotate(-180deg);
        }
    }
}

hr {
    footer & {
        border-color: rgba(255, 255, 255, 0.15) !important;
    }
}
