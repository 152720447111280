@each $grid-breakpoint,
$grid-gutter-width in $grid-gutter-widths {
    @include media-breakpoint-up($grid-breakpoint) {
        $grid-gutter-half-width: $grid-gutter-width / 2;

        .row:not(.no-gutters) {
            margin-right: -$grid-gutter-half-width;
            margin-left: -$grid-gutter-half-width;
        }

        .row:not(.no-gutters)>.col,
        .row:not(.no-gutters)>[class*="col-"] {
            padding-right: $grid-gutter-half-width;
            padding-left: $grid-gutter-half-width;
        }

        .container {
            padding-right: $grid-gutter-width/2;
            padding-left: $grid-gutter-width/2;
        }
    }
}



body {
    background-color: $white;
}

.wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    background-color: $white;

    .noBg &{
        background: linear-gradient(180deg, $gray 0%, $white 100%);
    }

    @include media-breakpoint-down (sm) {
        padding-top: 0;
    }
}


main {
    // @extend .py-4;
}

.bg-white {
    background-color: $white;
}


.color-primary {
    color: theme-color('primary');
}

.banner-strap {
    // background-color: $color-primary;
    // @extend .text-white;
    padding-top: 75px;
    padding-bottom: 75px;
    background-color: theme-color('primary-3');
}



.noBg {
    .navbar {
        &.bg-white {
            background: $white !important;
        }
    }

    .breadcrumb {
        background: #EFEFEF;
    }

}

figure {
    margin: 0 0 1rem;
}
