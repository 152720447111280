.btn {
    @extend .mt-2;
    @extend .btn-primary;
    // border: 3px solid theme-color('primary-2');
    border: none;
    border-radius: 28px !important;
    // background: none;
    // color: theme-color('primary');
    font-family: $font-family-navigational;
    font-size: $font-size-base;
    padding: .75em 1.5em;
    letter-spacing: 2px;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid $primary;

    @include media-breakpoint-down(sm) {
        font-size: $font-size-base*.9;
    }

    .lyner & {
        z-index: +1;
        border: 2px solid $lyner;
        background-color: $lyner;
    }

    .water & {
        z-index: +1;
        border: 2px solid $water;
        background-color: $water;
    }

    .solar & {
        z-index: +1;
        border: 2px solid $solar;
        background-color: $solar;
    }

    .heating & {
        z-index: +1;
        border: 2px solid $heating;
        background-color: $heating;
    }

    footer &,
    .navbar-light & {
        @extend .mt-0;
    }

    .navbar-light & {
        border: 2px solid $primary;
        background-color: $primary;
    }

    .card & {
        font-size: $font-size-base * 1.16667;
        padding: 0;
    }

    &:hover {
        cursor: pointer;
    }

    &:hover,
    &.outline {

        color: $primary;
        background: transparent;
        border: 2px solid $primary;

        .lyner & {
            color: $lyner;
            border: 2px solid $lyner;
        }

        .water & {
            color: $water;
            border: 2px solid $water;
        }

        .solar & {
            color: $solar;
            border: 2px solid $solar;
        }

        .heating & {
            color: $heating;
            border: 2px solid $heating;
        }

        .navbar-light & {
            color: $primary;
            border: 2px solid $primary;
            background-color: transparent;
        }
    }

    &.outline {

        &:hover {
            color: $white;
            background: transparent;
            border-color: $white;

            .lyner & {
                background-color: $lyner;
            }

            .water & {
                background-color: $water;
            }

            .solar & {
                background-color: $solar;
            }

            .heating & {
                background-color: $heating;
            }

            .navbar-light & {
                color: $primary;
                border: 2px solid $primary;
                background-color: transparent;
            }
        }

        footer &,
        .header-content & {
            color: $white !important;
            background: transparent !important;
            border: 2px solid $white !important;

            &:hover {
                color: $white !important;
                background: transparent !important;
                border-color: $white !important;
            }
        }
    }

    .header-content & {
        background-color: $primary;
    }
}

.card {
    .btn {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin: 0 auto;
        position: relative;
        bottom: -25px;
        text-align: center;
        font-size: $font-size-base;
        svg {
            margin-top: .55em;
            transform: rotate(180deg);
            path {
                fill: #fff !important;
            }
        }

        .noBtn & {
            display: none;
        }

        // &:before {
        //     // content: '→';
        //     position: absolute;
        //     top: 10px;
        //     left: 2px;
        //     width: 100%;
        //     height: 100%
        // }
    }

    &:hover {
        .btn {
            @extend .bg-primary;
        }
    }
}


.actionButton {
    white-space: nowrap;
    background: white;
    color: $primary !important;
    border-radius: 28px !important;
    @extend .h4;
    padding: 8px 16px;
    margin-bottom: 0!important;
}

.card-planner {
    border-radius: 28px !important;

}