.utility {
    // height: 2.25em;
    @extend .py-0;
    @extend .bg-primary;
    @extend .text-light;
    // border-bottom: 4px solid theme-color('primary');

    &.navbar-dark {
        border-bottom: none;
    }

    .nav-link {
        padding: 0.25rem;
        letter-spacing: 0;
    }

    a {
        font-weight: $font-weight-bold;
    }

    font-size: $font-size-base;
    font-family: $font-family-navigational;

    .dropdown {}

    .dropdown-menu {
        @extend .p-1;
        font-size: 1rem;
        border: none;
        border-radius: 0 !important;
    }

    .dropdown-item {
        @extend .p-0;
        width: auto;
    }
}


.badge {
    border-radius: 0;
    @extend .h4;
}