.color-overlay {
    z-index: 12;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    position: absolute;

    .lyner & {
        background: linear-gradient(180deg, $grad-lyner 0%, rgba($lyner, 0.9) 100%) !important;
    }

    .water & {
        background: linear-gradient(180deg, $grad-water 0%, rgba($water, 0.9) 100%) !important;
    }

    .solar & {
        background: linear-gradient(180deg, $grad-solar 0%, rgba($solar, 0.9) 100%) !important;
    }

    .heating & {
        background: linear-gradient(180deg, $grad-heating 0%, rgba($heating, 0.9) 100%) !important;
    }
}


.header-section {
    @extend .header-inner;
    @extend .p-0;
    @extend .jarallax;
    @extend .position-relative;
    @extend .text-light;
    @extend .o-hidden;

    // default:  @extend .bg-primary;
    background: linear-gradient(180deg, $grad-lyner 0%, $lyner 100%) !important;

    .lyner & {
        background: linear-gradient(180deg, $grad-lyner 0%, $lyner 100%) !important;
    }

    .water & {
        background: linear-gradient(180deg, $grad-water 0%, $water 100%) !important;
    }

    .solar & {
        background: linear-gradient(180deg, $grad-solar 0%, $solar 100%) !important;
    }

    .heating & {
        background: linear-gradient(180deg, $grad-heating 0%, $heating 100%) !important;
    }

    .noBg & {
        background: transparent !important;
    }

    .header-content {
        position: relative;
        @extend .container;
        @extend .py-0;
        @extend .mt-3;
        @extend .mt-md-0;
        min-height: 750px;
        // max-height: 750px;
        

        z-index: 22;
        @include media-breakpoint-down (sm) {
            min-height: 300px;
        }

        padding-top: 400px !important;

        .row {
            // @extend .layer-2;
            @extend .my-md-4;
        }

        .header-inner {
            // left: 0;
            bottom: $grid-gutter-width*2;
            position: absolute;
            // width: 70%;
            // @extend .container;
            @include media-breakpoint-down (sm) {
                bottom: 0;
            }
        }
    }

    &.story {
        .header-content {
            min-height: initial;
            padding-top: 200px !important;
        }
    }


    .header-inner {
        min-height: 150px;
    }

    h1 {
        @extend .display-4;
        font-weight: 300;
        line-height: 1.25 !important;
    }

    .lead {
        @extend .mb-0;
    }

    .jarallax-img {
        // mix-blend-mode: multiply;
        // object-position: 50% 0% !important;
    }

    div[id*="jarallax-container"] {
        // mix-blend-mode: multiply;
    }

    // img {
    //     filter: grayscale(0);
    // }

    .btn {
        // font-size: 1.25em !important;
        @extend .mt-4;
        background: none;
        color: white;

        &:hover {
            background: white;
            color: theme-color('primary');
        }
    }
}


.carousel-cell {
    padding: 0 2rem;
    margin: 0;
    position: absolute;
    // background: linear-gradient(to bottom, rgba($black,.2) 70%,rgba($black,.65) 100%);
    bottom: 0;
    width: calc(100% - 24px);
}