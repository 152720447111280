
.slider {
    .carousel-cell {
        background-size: cover;

    }

    .is-nav-selected {
        h3 {
            // color: red;
        }
    }

    &.slider-nav {
        // @extend .col-md-6;
        position: relative;
        top: -8em;
    }
}


.bg-solar {
    background: linear-gradient(315deg, color('solar') 0%, color('solar-light') 35.47%, color('solar-bright') 100%);
}
.bg-water {
    background: radial-gradient(ellipse at bottom left, darken($water, 5%), transparent);
}
.bg-heating {
    background: radial-gradient(ellipse at bottom left, darken($heating, 5%), transparent);
}
.bg-lyner {
    background: radial-gradient(ellipse at bottom left, darken($lyner, 5%), transparent);
}

.bg-water-gradient {
    background: linear-gradient(to bottom,#004780, #0076B0)
}

.bg-heater-gradient {
    background: linear-gradient(to bottom,#7F1418, #E2001A)
}

.header-section .carousel-cell,
header {

    .strong {
        font-weight: 700;
    }
    position: relative;

    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1;
    }

    section {
        z-index: +1;
    }

    &.fg-lyner {
        &:before {
            background-color: rgba($lyner,0.7) !important;
            background: linear-gradient(0deg, $lyner 0%, rgba($lyner,0.5) 46.16%, rgba($lyner,.4) 100%) !important;
        }
    }

    &.fg-solar {
        &:before {
            background-color: rgba($solar,0.7) !important;
            background: linear-gradient(0deg, $solar 0%, rgba($solar,0.5) 46.16%, rgba($solar,.4) 100%) !important;
        }
    }
    &.fg-sanitaer,
    &.fg-water {
        &:before {
            background-color: rgba($water,0.7) !important;
            background: linear-gradient(0deg, $water 0%, rgba($water,0.5) 46.16%, rgba($water,.4) 100%) !important;
        }
    }
    &.fg-heizung,
    &.fg-heating {
        &:before {
            background-color: rgba($heating,0.7) !important;
            background: linear-gradient(0deg, $heating 0%, rgba($heating,0.5) 46.16%, rgba($heating,.4) 100%) !important;
        }
    }

    .btn {
        border-color: white;
    }
}


