.accordion {
    .accordion-panel-title {
        font-family: $headings-font-family;
        font-weight: bold;
        color: theme-color('primary-1');
        font-size: 1.3333333em;
    }

    .badge {
        float: left !important;
        @extend .mr-3;
        min-width: 150px;
        letter-spacing: 2px;
        padding-left: 0;
        padding-right: 0;
    }

    p {
        @extend .mb-0;
    }

    .accordion-content {
        @extend .pt-2;

        p {
            @extend .mb-1;
        }
    }

    .border-bottom {
        @extend .pb-2;
        @extend .mb-2;
    }
}
