.tab-element {
    .nav {
        @extend .pb-2;
        @extend .border-bottom;
        @extend .justify-content-center;
    }

    .nav-item {
        @extend .mx-1;
        @extend .text-center;

        .eq {
            height: 100px;
            @include media-breakpoint-down(sm) {
                height: 70px;
                img {
                    height: 60px;
                }
            }
        }

        .label {
            color: theme-color('primary') !important;
            font-size: 1.25em;
            font-weight: 400;
            font-family: $font-family-navigational;
        }
    }
    
    .tab-content {
        @extend .mt-4;
    }
    
    .nav-link {
        filter: grayscale(80%);
        opacity: 0.3 !important;
        transition: all 0.5s ease-out;
        
        &.active,
        &:hover {
            filter: brightness(100%);
            opacity: 1 !important;
        }
    }
}

.water {
    .tab-element {
        .label {
            color: theme-color('water') !important;
        }
    }
}
.heating {
    .tab-element {
        .label {
            color: theme-color('heating') !important;
        }
    }
}