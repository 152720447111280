$dimensions: 14em;

.stoerer {
    position: absolute;
    z-index: +2;
    top: 11% !important;
    right: 7% !important;
    width: $dimensions;
    height: $dimensions;
    border-radius: $dimensions/2;
    padding: 1em;
    // border: 2px solid;
    background: $white;
    transform: rotate(-5deg);

    p {
        @extend .mb-0;
    }

    .text-center {
        width: 100%;
    }
}
