// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

@import 'components/accordion';
@import 'components/buttons';
@import 'components/breadcrumbs';
@import 'components/cards';
@import 'components/footer';
@import 'components/forms';
@import 'components/header-section';
@import 'components/latestNews';
@import 'components/layout';
@import 'components/loader';
@import 'components/maps';
@import 'components/modals';
@import 'components/navigation';
@import 'components/process';
@import 'components/slider';
@import 'components/stoerer';
@import 'components/tables';
@import 'components/tabs';
@import 'components/typography';
@import 'components/utility';


[data-countup] { 
    color: $lyner;
    .lyner & { color: $lyner; }
    .water & { color: $water; }
    .solar & { color: $solar }
    .heating & { color: $heating }
    font-size: $h1-font-size*2;
    font-weight: 600;
}

.bg-water { background-color: $water; }
.bg-solar { background-color: $solar }
.bg-heating { background-color: $heating }

.text-primary { color: $primary; }
.text-water { color: $water; }
.text-solar { color: $solar }
.text-heating { color: $heating }

.be-info {
    font-size: .75em;
    border: 2px solid $red;
    padding: .25em .5em;
    background-color: white;
    opacity: 0.7;
    position: absolute;
    z-index: 11;
}

.story-kacheln {
    .card {
        border: none;
        @extend .hover-shadow-sm;
    }

    h3 {
        color: white;
    }
    Bildergalerie
    .card-body {
        background: #efefef;
    }
}

.termine-kacheln,
.footer-form {

    .container {
        padding-bottom: 150px;
        @extend .layer-1;
    }

    @extend .banner-strap;
    @extend .pb-0;
    @extend .o-hidden;

    // @extend .bg-verlauf;

    // .card {
    //     border: none;
    //     // @extend .layer-1;
    //     // @extend .shadow-lg;
    // }

    .card-body {
        background-color: #fff;
    }
}

.story-kacheln,
.termine-kacheln,
.blog-kacheln,
.more-items {

    h4 {
        font-family: $headings-font-family;
        font-size: 1.33333333em;
        color: theme-color('primary');
    }

    .card {
        border: none;
        min-height: 380px;
        border-radius: 0 !important;
        @extend .layer-1;
        @extend .shadow-lg;

        .badge {
            z-index: 10;
            padding: 0.5rem;
            font-size: .95rem;
            border-radius: 0;
        }
    }

    .card-img-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-body {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        p {
            color: theme-color('primary-2');
        }

        .card-content{
            p{
                font-weight: 400;
            }
        }

    }

    .itemInfo {
        @extend .small;
        @extend .mb-2;
        font-weight: $font-weight-bold;

        span {
            color: white;
        }
    }


    .blog-card {
        .card-body {
            color: white;

            h4 {
                color: white;
            }

            padding-top: 70%;

            .badge {
                border: none;
            }

            &:before {
                content: '';
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
                position: absolute;
            }
        }
    }

}

.bg-alternate {
    .card-body {
        background: theme-color('primary-2');

        p {
            @extend .text-white;
        }
    }
}


.blog-card {
    height: 460px !important;

    @include media-breakpoint-down(lg) {
        height: 300px !important;
    }

    .card-body {
        @extend .d-flex;
        @extend .flex-column;
    }

}



.termine-kacheln,
.footer-form {

    h1,
    h2,
    h3 {
        @extend .text-light;
    }

    // @extend .bg-verlauf;
    // padding-bottom: 100px !important;
}

.divider {
    .bg-primary-4 {
        fill: lighten(theme-color('primary-2'), 35%) !important;
    }
}

img.rounded {
    border-radius: $border-radius !important;
}


.jonnitto-prettyembed-wrapper {
    border-radius: 0 !important;
    overflow: hidden;
}

.jonnitto-image,
.jonnitto-prettyembed-wrapper {
    @extend p;
}
