.nav-wrapper {
    left: 50%;
    width: 100%;
    z-index: 99;
    position: fixed;
    max-width: 1280px;
    transform: translateX(-50%);
    background: color('white');
    min-height: auto !important;
}

.navbar-container {
    /* offset-x | offset-y | blur-radius | spread-radius | color */

    font-size: $font-size-base*1.3333333333 !important;
    font-family: $font-family-navigational;
    // letter-spacing: .5px;
    // box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down (lg) {
        // font-size: 1.1667em !important;
    }

    @include media-breakpoint-down (sm) {
        font-size: $font-size-base !important;
    }

    .navbar {
        box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);

        .container {
            @include media-breakpoint-down (sm) {
                // margin: 0 1.5em;
            }
        }
    }

    &+div .mobile-nav {
        margin-top: 138px;
        background-color: $primary;
        @include media-breakpoint-down (sm) {
            margin-top: 117px;
        }
    }

    .navbar:not(.utility) {
        // padding: 0;
        // height: 114px;
        transition: height 0.4s ease;
    }

    .navbar-toggler {
        border: $border-width solid $primary; // remove default button style
        background-color: transparent; // remove default button style
        border-radius: $btn-border-radius*2;
        width: $btn-border-radius*11.5;
        @include media-breakpoint-down (sm) {
            width: $btn-border-radius*10.5;
        }

        .icon {
            margin: 0.5rem;
            height: 2rem;
            &.navbar-toggler-close {
                margin: .7rem;
                @include media-breakpoint-down (sm) {
                    margin: 0.5rem;
                }
            }        
        }

        &:hover {
            border: $border-width solid $primary;
        }
    }

    .nav-item {
        a {
            font-weight: 400;
            white-space: nowrap;
            color: #9b9b9b !important;

            &.current,
            &:hover {
                color: $primary !important;
            }
        }

        @include media-breakpoint-down(md) {
            a {
                color: $white;
            }
            
            &:last-child {
                border: none;
            }
        }

        .bordered {
            @include media-breakpoint-down(md) {
                border: none !important;
            }
        }

        .icon {
            display: none;
        }
    }

    .dropdown-item {
        font-weight: 400;

        .icon {
            display: initial;
        }

        color: #666 !important;

        span {
            border-bottom: none;
        }

        &.active,
        &:hover {
            color: theme-color('primary-2') !important;
        }
    }

    .navbar-light {

        .is-active,
        .is-current {
            color: $black;
        }
    }

    .navbar-dark {

        .is-active,
        .is-current,
        .nav-link:hover {
            color: $white !important;
        }

        .navbar-nav {
            .nav-link {
                color: rgba($white, 0.95)
            }
        }
    }

    .is-active,
    .is-current {

        opacity: 1 !important;

        .bordered {
            @extend .pb-2;
            border-bottom: 2px solid theme-color('primary');
        }

        &.dropdown-item {
            color: theme-color('primary-2') !important;
        }

    }

    .button {
        @extend .btn;
        @extend .btn-default;
        @extend .ml-lg-3;
        @extend .mt-2;
    }
}


.navbar-brand img {
    height: 70px;
    max-width: 298px;
    transition: .4s;
    padding-right: 0;

    [data-sticky="top"].position-fixed & {
        height: 50px;
        padding-right: 20px;
    }


    @include media-breakpoint-down(md) {
        height: 55px;
        margin-top: .5em;

        footer & {
            height: 35px;
        }
        [data-sticky="top"].position-fixed & {
            height: 55px;
        }
    }

    @include media-breakpoint-down(sm) {
        height: 40px;

        [data-sticky="top"].position-fixed & {
            height: 40px;
        }
    }

    .scrolled & {
        height: 50px;
        margin: 0 .75em 0 0;
        transition: .3s;

        @include media-breakpoint-down(sm) {
            height: 40px;
        }
    }
}


// Counter Settings
.nav-link {
    @extend .position-relative;

    .count {
        @extend .position-absolute;
        right: 0px;
        top: 0px;

        width: 16px !important;
        height: 16px !important;
        line-height: 16px !important;
        font-size: 0.65rem;
        background: theme-color('primary-2');
        text-align: center;
        border-radius: 50%;
        color: white;
        font-weight: $font-weight-normal;

        @include media-breakpoint-down (md) {
            top: .5rem;
            padding-top: .04rem;
            margin-left: .2rem;
            right: inherit;
        }
    }
}
