footer {
    font-size: 18px;

    @extend .pb-3;
    @extend .pt-0;

    @extend .bg-primary;
    @extend .text-light;

    // background: linear-gradient(0deg, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, .5) 100%), theme-color('primary');

    .container {
        @extend .d-flex;
        @extend .flex-column;
    }

    min-height: 350px;

    .divider {
        transform: scaleX(-1);
    }

    color: color('white') !important;


    .nav-main {
        @extend .nav;

        .link-app,
        .nav-link {
            color: white !important;
            font-size: 1.17em !important;
            font-family: $font-family-navigational;
            font-weight: $font-weight-normal;
            letter-spacing: .5px;
            opacity: 0.5 !important;

            &:hover {
                text-decoration: underline;
                opacity: 1 !important;
            }
        }

        .nav-link {
            &.current {
                // font-weight: bold;
                opacity: 1 !important;
            }
        }

        .nav-item {
            @extend .d-none;
            @extend .d-md-block;

            &:last-child {
                a {
                    @extend .pr-0;
                }
            }
        }
    }

    .meta-nav {

        font-size: 18px !important;

        .meta-item {
            display: inline-block;
            .trenner {
                @extend .px-1;
            }

            &:last-child {
                .trenner {
                    display: none;
                }
            }
        }

        .link {
            @extend .pb-0;
            // @extend .pr-2;
            opacity: 1 !important;

            &:hover {
                text-decoration: none !important;
            }

        }
    }

    .social-nav {
        @extend .nav;

        .nav-item {
            @extend .ml-2;

            a {
                background: white;
                display: block;
                width: 2em;
                height: 2em;
                color: theme-color('primary');
                position: relative;

                i {
                    margin: 0;
                    font-size: 1.5em;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-right: -50%;
                    transform: translate(-50%, -50%)
                }

                &:hover {
                    background: theme-color('primary-3');
                }
            }
        }
    }

    a {
        font-weight: $font-weight-normal;
        color: white;

        &:hover {
            text-decoration: underline;
            color: white;
        }
    }



    .font-regular {
        a {
            text-decoration: underline;
        }
    }

    .nav-link {
        // &:first-child {
        //     a {
        //         @extend .pl-0;
        //     }
        // }

        // &:last-child {
        //     a {
        //         @extend .pr-0;
        //     }
        // }

        @include media-breakpoint-down(sm) {
            display: block;
            width: 100%;
            border-top: 1px solid $primary;
        }
    }

    .button {
        @extend .btn;
        @extend .outline;
        // @extend .btn-default;
        // @extend .ml-lg-3;
        // font-size: $font-size-base;
        // @extend .mt-2;
        // @extend .my-0;
        // @extend .py-2;
        // height: auto;
    }

    .list {
        ul {
            list-style-type: none;
            padding-left: 1.5em;

            .nav-item {
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            // li {
            //     &:before {
            //         content: '─' !important;
            //         // content: '→' !important;
            //         // content: '⇾' !important;
            //         position: absolute;
            //         margin-left: -1.5em;
            //         color: $white;
            //     }
            // }
        }
    }
}