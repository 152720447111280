// 
// 
// badge.scss
//
//

.badge{
  font-family: $font-family-sans-serif;
  font-weight: 400;
  font-size: $font-size-base*.889;
  text-transform: uppercase;
  letter-spacing: .1em !important;
  position: relative;
  bottom: 1px;
  vertical-align: middle;
  .icon{
    height: .75rem;
    vertical-align: top;
  }
  &.position-absolute{
    bottom: auto;
  }
  &.badge-top{
    position: absolute;
    top: -$spacer/2;
    bottom: auto;
  }
}