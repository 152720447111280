.latestNews {
    @extend .container;

    .card {
        @extend .card-body;
        @extend .overlay;
        @extend .border-0;
        @extend .text-light;
        @extend .mb-md-0;
        @extend .justify-content-end;

        // Border Radius for card
        &:after {
            border-radius: 40px !important;
            @include media-breakpoint-down(md){
                border-radius: 30px !important;
            }
        }
    }

    .badge {
        @extend .mr-1;
        @extend .mb-2;
        border: 2px solid theme-color('primary-2');
        background: white;
        // #1F9EE5;
        @extend .badge-light;
    }

    .itemLarge {
        // @extend .col-md-6;
        // @extend .col-lg-8;
        @extend .d-flex;

        .badge {
            @extend .badge-light;
            border: none;
        }

    }

    .itemsSmall {
        // @extend .col-md-6;
        // @extend .col-lg-4;

        h6 {
            color: #01487C;

        }
        .row:not(.no-gutters) > [class*="col-"] {
            padding-left: 0;
            padding-right: 0;
        }

        // .badge {
        //     @extend .bg-primary;
        //     @extend .text-light;
        // }
    }
}
