// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Uncomment the variables below and change the hex values.

$primary:       #322875;

$primary-2:     #D8232A;
$primary-3:     #EFEFEF;

$lyner:         #322875;
$water:         #004780;
$solar:         #7F5319;
$heating:       #7F1418;
$grad-lyner:   rgba(50,40,117,0);
$grad-water:   rgba(0,118,176,0);
$grad-solar:   rgba(248,179,52,0);
$grad-heating: rgba(226,0,26,0);

$secondary:     $heating;
$tertiary:      $solar;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "water":      $water,
    "heating":    $heating,
    "solar":      $solar,
);

$border-radius: 20px;
$border-width: 2px;
$border-color: #d8d8d8;

$font-family-sans-serif:      'Roboto Condensed', sans-serif;
$font-family-base:            'Titillium Web', serif;
$headings-font-family:        'Titillium Web', serif;
$font-family-navigational:    'Roboto Condensed', sans-serif;

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          700;
$font-weight-bold:            700;

$headings-font-weight:        300;
$display1-weight:             200;
$display2-weight:             200;
$display3-weight:             200;
$display4-weight:             200;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 960px,
  xl: 1440px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1280px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");


$grid-gutter-widths: (
    sm: 30px,
    md: 50px,
    lg: 50px
);