table {
    @extend .table;
}

.table {
    font-family: $headings-font-family;
    width: 100%;

    table {
        width: 100%;
    }

    text-align: left;

    td {
        @extend .px-0;
    }

    td:first-child {
        // color: red;
        min-width: 200px;
        font-weight: $font-weight-bold;
    }
}

.table-links {
    a {
        font-weight: $font-weight-bold;
    }

    svg {
        transform: rotate(-180deg);
        display: none;
    }

    .hasLink {
        &.hover {
            cursor: pointer !important;
        }
    }

    .hasLink {
        svg {
            display: inline-block;
        }
    }
}